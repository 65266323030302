<template>
  <div class="uk-flex uk-flex-middle" uk-height-viewport>
    <div class="uk-width-2-3@m uk-width-1-2@s m-auto rounded">
      <div class="uk-child-width-1-2@m uk-grid-collapse login-card" uk-grid>
        <div class="uk-margin-auto-vertical uk-text-center uk-animation-scale-up p-5 uk-light">
          <template v-if="company">
            <img :src="company.logo_link" class="company-logo">
            <h3 class="mb-4">{{ company.name }}</h3>
            <p class="d-none">{{ company.type }}</p>
          </template>
        </div>
        <slot :company="company"></slot>
      </div>
      <img :src="loginLogoFooter" alt="" class="d-block mt-5" style="margin:0 auto;width:120px;">
    </div>
  </div>
</template>
<script>
import {GET_ITEM_DETAIL_BY_ID as COMPANY_DETAIL,} from "@/core/services/store/REST.module";

export default {
  name: "AuthLayout",
  beforeCreate() {
    if (['production', 'staging'].includes(process.env.NODE_ENV)) {
      let self = this
      let url = window.location.href
      const alias = window.location.hostname.split(".")[0];
      if (!url.includes('www.')) {
        this.$store.dispatch(COMPANY_DETAIL, {
          url: 'api/company/info',
          filters: {
            alias: alias
          }
        }).then((result) => {
              if (result.status) {
                if (Object.keys(result.data).length !== 0) {
                  self.company = result.data;
                } else {
                  window.location.href = process.env.VUE_APP_APP_URL;
                }
              }
            });
      }
    }
  },
  mounted() {
    let url = window.location.href
    if (url.includes('www.')) {
      this.company = {
        logo_link: '/media/images/anzera-logo-medium.png',
        name: 'Anzera Öğrenme Teknolojileri A.Ş.',
        type: 'Dijital Öğrenme Platformu',
        is_allowed_register_user: 0,
      }
    }
  },
  data() {
    return {
      company: {
        logo_link: '/media/images/anzera-logo-medium.png',
        name: 'Anzera Öğrenme Teknolojileri A.Ş.',
        type: 'Dijital Öğrenme Platformu',
        is_allowed_register_user: 0,
      },
    };
  },
  computed: {
    loginLogoFooter() {
      return process.env.BASE_URL + "media/images/logo-dark.svg";
    }
  },
}

</script>

<style scoped>

</style>
